<template>
  <section v-if="guidesContext.length > 0" class="guides">
    <div class="sub-title">
      <SectionTitle v-if="route.params.inspirationsslug" class="title">{{ title }}</SectionTitle>
      <h3 v-else class="title">{{ title }}</h3>
      <NuxtLink :to="`${pathGuide}${guideLink}/`">
        <div class="more">En savoir plus</div>
      </NuxtLink>
    </div>
    <div class="slider-container">
      <SwiperArrows
        v-if="!breakpoint.isMobile"
        v-model="swiper"
        class="arrow"
        which="left"
        size="small"
        whole-group
      />
      <SwiperWrapper
        v-model="swiper"
        class="cards"
        :slides-per-view="'auto'"
        :space-between="18"
        :mousewheel="true"
      >
        <SwiperSlide v-for="guide in guidesContext" :key="guide.id" class="card">
          <NuxtLink
            v-if="guide.attributes.slug"
            :to="`${pathGuide}${guideLink}/${guide.attributes.slug}/`"
          >
            <div>
              <NuxtImg
                v-if="
                  guide?.attributes?.header?.data?.attributes?.url ?? guide?.attributes?.header_path
                "
                provider="twicpics"
                class="image"
                :src="
                  guide?.attributes?.header?.data?.attributes?.url ?? guide?.attributes?.header_path
                "
                :alt="guide.attributes?.title"
                width="308px"
                height="220px"
              />
              <div class="dark-filter" />
              <h4 class="text">{{ guide.attributes?.title }}</h4>
            </div></NuxtLink
          >
        </SwiperSlide>
      </SwiperWrapper>
      <SwiperArrows
        v-if="!breakpoint.isMobile"
        v-model="swiper"
        class="arrow"
        which="right"
        size="small"
        whole-group
      />
    </div>
  </section>
</template>
<script lang="ts" setup>
import { MvGuide } from '@/lib/strapi-types/MvGuide';
import { SwiperBinding } from '@/lib/types/swiper';

const { guides, context, pathGuide, rootGuide } = defineProps<{
  guides: MvGuide[];
  context?: string;
  title?: string;
  pathGuide?: string;
  rootGuide?: boolean;
}>();
const swiper = $ref<SwiperBinding>();
const breakpoint = useBreakpoints();
const route = useRoute();
const guidesContext = computed(() => {
  if (context) {
    return guides?.filter(
      guide => guide?.attributes.mv_guide_tag?.data.attributes.uid_guide_tag === context
    );
  }
  return guides;
});

const guideLink = `${rootGuide ? 'guide' : route.params.inspirationsslug ? context : context?.split('-').pop()}`;
</script>
<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/metrics.scss';
@use '$/misc.scss';
@use '$/mouse.scss';
@use '$/text-ellipsis.scss';
@use '$/z-index.scss';

.sub-title {
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  .more {
    @include misc.bold-link();
  }

  .guides {
    .title {
      margin-left: 40px;
      font-size: 24px;

      @include breakpoints.mobile() {
        margin-left: 20px;
        font-size: 18px;
      }
    }
  }
}

.slider-container {
  gap: 8px;
  align-items: center;
  width: metrics.$inner-width + 2 * 32px;

  @include breakpoints.mobile() {
    width: 100vw;
    padding-left: metrics.$mobile-inner-margin;
  }

  .arrow {
    @include breakpoints.mobile() {
      display: none;
    }
  }

  .cards:deep(.card) {
    position: relative;

    overflow: hidden;
    flex-direction: column;
    justify-content: flex-end;

    width: 308px;
    height: 220px;
    padding: 16px;

    border-radius: 8px;

    @include breakpoints.mobile() {
      width: 280px;
      height: 208px;
    }

    .image,
    .dark-filter {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    .image {
      height: 100%;
    }

    .dark-filter {
      height: 101px;
      background: linear-gradient(360deg, rgb(0 0 0 / 80%) 0%, rgb(0 0 0 / 0%) 100%);
    }

    .text {
      z-index: z-index.$any-on-top;

      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      color: white;
      white-space: pre-wrap;

      @include breakpoints.mobile() {
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
      }
    }
  }
}
</style>
